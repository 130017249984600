/* src/animations.css */

.fade-in-up {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-down {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-right {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-out-up {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  position: absolute;
}

.fade-out-down {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  position: absolute;
}

.fade-out-left {
  opacity: 0;
  transform: translateX(-30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  position: absolute;
}

.fade-out-right {
  opacity: 0;
  transform: translateX(0px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  position: absolute;
}

.fade-in-general {
	opacity: 1;
	visibility: visible;
	transition: opacity 1.5s ease-out;
}

.fade-out-general {
	opacity: 0;
	visibility: hidden;
	transition: opacity 2s ease-out;
}