.App {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

*, *:before, *:after {
	box-sizing: inherit;
}

.info-text {
  color: var(--text-color-info)
}

button {
  background-color: rgba(45,212,191,.1);
  border: none;
  border-radius: 50px;
  color: var(--blue-text-main);
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

button:hover {
  background-color: var(--blue-text-second);
  color: white;
} 

a {
  text-decoration: none;
}


h1 {
  margin: 0;
}

h2 {
  margin: 0;
  color: var(--blue-text-second);
}

p {
  color: var(--blue-text-info);
}
