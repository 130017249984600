.project-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.project-card {
  position: relative;
  width: max(450px, 30vw);
  height: max(300px, 20vw);
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 1rem;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.project-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  opacity: 1;
  text-align: center;
}

.project-card .overlay.hidden {
  opacity: 0;
}

.project-card .text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-card:hover .text-content {
  opacity: 0;
}

.project-card:hover img {
  opacity: 0.3;
}

.project-card .hover-content {
  display: none;
}

.project-card:hover .hover-content {
  display: block;
}
@media only screen and (max-width: 650px) {
  .project-card {
    height: 50vw;
    width: 75vw;
  }
}