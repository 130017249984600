.landing {
    background-color: black;
    height: 100vh;
    width: 100vw;
    
}
.center {
    position: absolute;
    text-align: center;
    width: 40vw;
    margin-left: 30vw;
    margin-right: 30vw;
    top: 25vh; 
    color: white;
    z-index: 2;
    color: rgb(226 232 240);
    font-style: sans-serif;
    font-size: clamp(16px, 6vw, 64px);
    background: -webkit-linear-gradient(#2B2974, #FFBDBA, #4FA5E4, #E14BC9,#151149);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.spline {
    position: absolute;
    bottom: 2vh;
    width: 100vw;
}
