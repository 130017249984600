.timeline {
	position: relative;
}

.timeline-motion {
	display: flex;
	gap: 1rem;
}

.timeline-scrolling {
	position: sticky;
	top: 0px;
	display: flex;
	height: 100vh;
	align-items: center;
	overflow: hidden;
}

.timeline-card {
	position: relative;
	height: 450px;
	width: 50vw;
	margin-left: 10vw;
	margin-right: 5vw;
	overflow: hidden;
}

.timeline-card-inner {
	position: absolute;
	inset: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.timeline-on-screen {
	opacity: 1;
	visibility: visible;
	transition: opacity 1.5s ease-out;
}

.timeline-off-screen {
	opacity: 0;
	visibility: hidden;
	transition: opacity 2s ease-out;
}

.timeline-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px 20px;
	background-color: var(--blue-text-container);
	overflow: hidden;
	position: relative;
	border-radius: 30px;

	flex-basis: 75%; 

	transition: flex-basis 0.5s ease-out;
}

.timeline-item:hover {
	flex-basis: 100%;
}

.timeline-item:hover,
.timeline-item:hover .timeline-datebox,
.timeline-item:hover .timeline-title_title,
.timeline-item:hover .timeline-title_company {
	text-decoration: none;
	color: #FFF;
}

.timeline-item:hover .timeline-circle-bg {
	-webkit-transform: scale(25);
	-ms-transform: scale(25);
	transform: scale(25);
}

.timeline-title {
	min-height: 87px;
	margin: 0 0 25px;

	overflow: hidden;

	font-weight: bold;
	color: var(--blue-text-main);

	z-index: 2;
	position: relative;
}

.timeline-content {
	overflow: hidden;
	font-weight: bold;
	z-index: 2;
	position: relative;
}

.timeline-datebox {
	font-size: 18px;
	color: var(--blue-text-info);
	z-index: 2;
	position: relative;
}

.timeline-date {
	font-weight: bold;
	-webkit-transition: color .5s ease;
	-o-transition: color .5s ease;
	transition: color .5s ease
}

.timeline-title_company {
	color: var(--blue-text-main);
	font-size: 2rem;
}

.timeline-title_title {
	color: var(--blue-text-second);
	font-size: 1.6rem;
}

.timeline-circle-bg {
	height: 128px;
	width: 128px;

	z-index: 1;
	position: absolute;
	top: -75px;
	right: -75px;

	border-radius: 50%;

	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}

.timeline-card-info {
	font-size: 1.1rem;
}

@media only screen and (max-width: 768px) {
	.timeline-card {
		width: 85vw;
		height: 550px;
		margin-left: 6vw;
		margin-right: 3vw;
	}
}