:root {
  --blue-text-info: rgb(128, 138, 151);
  --blue-background: rgb(9, 17, 36);
  --blue-text-container: rgb(32, 37, 59);
  --blue-text-main: rgb(27, 245, 212);
  --blue-text-second: rgba(59,130,246,.5)
}

body {
  background-color: var(--blue-background);
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  color: var(--blue-text-main);
  font-family: Libre Franklin;
}


/* @media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 768px) {} */