.navbar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
}

.navbar {
  display: inline-block;
  padding: 20px;
  border-radius: 50px;
  font-size: 1.6rem;
  margin-top: 20px;
}

.navbar-down {
  background-color: rgba(32, 37, 59, 0.8);
  backdrop-filter: blur(5px);
}

.navLink {
  margin: 0 15px;
  color: cyan;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
  color: var(--blue-text-second);
}

.navLink.active {
  color: var(--blue-text-main);
}

.header-container {
  display: flex;
  align-items: center;
  margin: 5rem;
  padding: 2rem;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.header-title {
  margin: 0;
  padding-right: 40px; /* Adjust space between title and line */
}

.header-line {
  flex-grow: 1;
  height: 1px;
  background-color: var(--blue-text-info);
}

@media screen and (max-width: 1400px) {
  .navbar {
    font-size: 1.4rem;
  }
  .header-container {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1000px) {
  .navbar {
    font-size: 1.2rem;
  }
  .header-container {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 800px) {
  .navbar {
    font-size: 1rem;
  }

  .header-container {
    padding: 1rem;
    margin: 3rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    border-radius: 50px;
    font-size: 0.8rem;
    margin-top: 20px;
    padding: 0.5rem 0;
  }

  .header-container {
    padding: 0.8rem;
    margin: 2rem 1rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 400px) {
  .navbar {
    border-radius: 50px;
    font-size: 0.6rem;
    margin-top: 20px;
    padding: 0.5rem 0;
  }

  .header-container {
    padding: 0.8rem;
    margin: 1rem 0.5rem;
    font-size: 0.6rem;
  }
}
