.about {
  display: flex;
  font-size: 1.2rem;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.about-photo {
  padding: 0 3rem;
}

.about-image {
  width: 350px;
  border-radius: 100px;
}

.about-content {
  padding: 0 3rem;
  width: min(100vw, 750px);
}


@media screen and (max-width: 800px) {
  .about-image {
    width: 300px;
  }
}

@media screen and (max-width: 450px) {
  .about-image {
    width: 225px;
  }
}
