footer {
    font-size: 16px;
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}

footer section.card {
    height: 380px;
    width: min(750px, 80vw);
    padding: 30px;
    background-color: var(--blue-text-container);
    border-radius: 50px;
    overflow: hidden;
}

.footer-header {
    margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="message"] {
  background-color: var(--blue-text-info);
  border: none;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  margin-top: 10px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="message"]:focus {
  outline: none;
  box-shadow: 0 0 3px 3px rgba(31, 110, 235, 0.3);
}

div .bottom-row {
    height: 20%;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

div .svg-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.svg-logo svg {
    width: 25px;
    height: 25px;
    fill: #777;
}

.svg-logo a {
    margin-left: 15px;
}


.svg-logo a svg:hover {
    transform: scale(1.25);
    fill: white;
    transition: transform .2s ease-in-out;
}
